<template>
  <div class="content-area__content bot-maker">
    <transition :name="routerTransition" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
import { themeConfig } from '@/../themeConfig.js'
export default {
  data() {
    return {
      routerTransition: themeConfig.routerTransition || 'none'
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/vuesax/apps/chat.scss';
</style>
